import { useMemo, useState } from 'react';
import { apiBaseUrl } from '../config/appApiConfig';
import { sendApiRequestWithAccessToken } from '/lib/msal';
import type { Dashboard } from './types/Dashboard';
import useApiLoader from '../lib/useApiLoader';

//string ProjectID, string firstName, string lastName, string itemsForSale, string companyName, string ProjectStatus,
async function DashboardMonthlyPayouts_GetBySalesperson(salesPersonList: string, startYear: number, stopYear: number): Promise<Dashboard[]> {
  const url = `${apiBaseUrl}DashboardMonthlyPayouts_GetBySalesperson?salesPersonUserName=${salesPersonList}&startYear=${startYear}&stopYear=${stopYear}`;
  const data = await sendApiRequestWithAccessToken(url, null, 'GET');
  return data;
}

function useDashboardMonthlyPayouts_GetBySalesperson(salesPersonList: string, startYear: number, stopYear: number) {
  const [data, setData] = useState<Dashboard[]>([]);

  const loaderState = useApiLoader<Dashboard[]>(
    useMemo(
      () => ({
        loader: async () => {
          // call API server
          const data = await DashboardMonthlyPayouts_GetBySalesperson(salesPersonList,startYear,stopYear);
          return data;
        
        },
        onLoaderData: data => {
          if (data) setData(data);
        },
      }),
      [salesPersonList, startYear, stopYear]
    )
  );

 
  return { data, ...loaderState };
}

export { DashboardMonthlyPayouts_GetBySalesperson, useDashboardMonthlyPayouts_GetBySalesperson };
