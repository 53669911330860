import { ChangeEvent, useCallback } from 'react';
import React, { useState } from 'react';
import Moment from 'moment';
import {  PageCanAccessProjectsAdminFeatures } from '/lib/appContextStore';

import {
    Button,
    DataGrid,
    Column, ColumnFixing,
    Export,
    Editing,
    ColumnChooser, ColumnChooserSearch, ColumnChooserSelection, Position, Scrolling,
    DataGridTypes, Summary, TotalItem, FilterRow, HeaderFilter, Search, SearchPanel,
    RequiredRule,
    Lookup,
    Toolbar,
    Item,
    
} from 'devextreme-react/data-grid';



import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Project } from '/api/types/Project';
import { JobType } from '/api/types/JobType';
import { ProjectStatus } from '/api/types/ProjectStatus';
import { InitNewRowEvent } from 'devextreme/ui/data_grid';

const onExporting = (e: DataGridTypes.ExportingEvent) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');

  exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PCApps-TimesheetDXExport.xlsx');
    });
  });
};

const addChangeOrder = () => {
  // selectedItemKeys.forEach((key) => {
  //   dataSource.store().remove(key);
  // });
  // setSelectedItemKeys([]);
  // dataSource.reload();
};

interface CtrGridProjectsChangeOrdersProps {
  className?: string;
  allowInGridEditing?: boolean;
  currentFilterDateStart?: string;
  dataList: Project[];
  jobTypes: JobType[];
  projectStatuses: ProjectStatus[];
  windowWidth: number;
  windowHeight: number;
  onRowClicked?: (_host: Project) => void;
  onRowDoubleClicked?: (_host: Project) => void;
  onAddRecordClick?: (_host: Project) => void;
  onEditRecordClick?: (_host: Project) => void;
  onRefreshParentComponent?: (_isRefetchData: boolean) => void;

}

const CtrGridProjectsChangeOrders = React.memo(function CtrGridProjectsChangeOrders({
  className,
  allowInGridEditing = true,
  currentFilterDateStart = Moment(new Date(Date.now())).format('YYYY-MM-DD'),
  dataList,
  jobTypes,
  projectStatuses,
  windowWidth = 1024,
  windowHeight = 800,
  onRowClicked,
  onRowDoubleClicked,
  onAddRecordClick,
  onEditRecordClick,
  onRefreshParentComponent,
}: CtrGridProjectsChangeOrdersProps) {
  const [isEditableGrid, setIsEditableGrid] = useState(false)

  function handleChangeSwitchEditableGrid(e: ChangeEvent<HTMLInputElement>) {
    setIsEditableGrid(e.target.checked)
    onRefreshParentComponent(false)
  }

  function handleRowClick(e: Project) {
    

    //if (isEditableGrid === false) {
    //onRowClicked(e);
    //}
  }

  function onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach(function(item) {
      if (item.name === "searchPanel") {
        item.location = 'before';
      }
      if (item.name === "groupPanel") {
        item.location = 'after';
      }
    })
  }
  // --------------------------------------
  

  
  //In-Line grid editing
  const isSalespersonLoggedIn = PageCanAccessProjectsAdminFeatures();

  const [rows, setRows] = React.useState(dataList);
  //const [rows, setRows] = React.useState(new Date(Date.now()));
  
  const screenWidthAdjustGridWidth = ((windowWidth !== 0 ? (((windowWidth - 10) / windowWidth) * 100).toString() + '%' : '100%'));
  const screenWidthAdjustGridHeight = ((windowWidth < 900 || windowHeight < 780 ? '550px' : (windowHeight - 320 - 230 - 370).toString() + 'px'));

  return (
    <div style={{width: (screenWidthAdjustGridWidth)}}>
      <DataGrid
        id='dxGridProjects'
        dataSource={dataList}
        allowColumnReordering={true}
        showBorders={true}
        
        hoverStateEnabled={true}
        focusedRowEnabled={true}
        keyExpr="projectGuid"
        allowColumnResizing={true}
        columnResizingMode='widget'
        onExporting={onExporting}
        // onRowClick={(e) => handleRowClick(e.data)}
        onFocusedRowChanged={(e) => handleRowClick(e.row.data)}
        onToolbarPreparing={onToolbarPreparing}
        height={(250)}
        width={'100%'}
        wordWrapEnabled={true}
        style={{marginBottom: '10px', marginRight: '20px'}}
       
      >
        <Scrolling mode="infinite" columnRenderingMode="virtual"/>
        
        <HeaderFilter visible={true} />
        <SearchPanel
          visible={true}
          width={250}
          placeholder="Search..." />
        {/* <Editing
        mode="batch"
        useIcons={true}
        allowUpdating={true}
        allowDeleting={true}
        allowAdding={true}
        
        /> */}
        {/* <Column type="buttons" width={40} showInColumnChooser={false} fixedPosition='left' >
          <Button name="edit" onClick={(e) => onEditRecordClick(e.row.data)} icon='edit' />
        </Column> */}
        <Column dataField="jobNumber" caption="Job #" width={120} allowEditing={true} sortOrder='desc'>
          <RequiredRule />
        </Column>
        <Column dataField="projectName" caption='Project Name' width={200} allowEditing={true}>
          <RequiredRule />
        </Column>
        <Column dataField="bookDate" caption='Book Date' width={90} dataType='date' allowEditing={true}></Column>

        <Column dataField="salesPersonUserName" caption='Salesperson' width={120} allowEditing={true}>
          <RequiredRule />
        </Column>
        <Column dataField="jobTypeId" caption='Job Type' width={130} dataType='number' allowEditing={true}>
          <Lookup
            dataSource={jobTypes}
            displayExpr="jobTypeName"
            valueExpr="jobTypeId"
          />
          <RequiredRule />
        </Column>
        <Column dataField="creditPercentage" caption='% Credit' width={100} dataType='number' format={'percent'} allowEditing={true} editorOptions={{format: 'percent'}}></Column>
        <Column dataField="sellPrice" caption='Sell Price' width={120} dataType='number' format={'$#,##0.00'} allowEditing={true} ></Column>
        <Column dataField="costAmount" caption='Cost' width={120}  dataType='number' format={'$#,##0.00'} allowEditing={true} ></Column>
        <Column dataField="bookedGrossMargin" caption='Booked Gross Margin' width={100}  dataType='number' format={'$#,##0.00'}  allowEditing={false}></Column>
        <Column dataField="payGrossMargin" caption='Pay Gross Margin' width={100}  dataType='number' format={'$#,##0.00'} allowEditing={false}></Column>
        <Column dataField="grossMarginPercentage" caption='GM %' width={70} dataType='number' format={'#0.00%'}  allowEditing={false}></Column>
        <Column dataField="totalCommision" caption='Total Commision'  width={100} dataType='number' format={'$#,##0.00'}  allowEditing={false}></Column>
        <Column dataField="firstPayoutAmount" caption='First Payout Amount' width={100} dataType='number' format={'$#,##0.00'} allowEditing={false}></Column>
        <Column dataField="remainingPayoutAmount" caption='Remaining Payout Amount' width={100} dataType='number' format={'$#,##0.00'} allowEditing={false}></Column>
        <Column dataField="collectedPercentage" caption='% collected' width={100} dataType='number' format={'#0.00%'}  allowEditing={false}></Column>
        <Column dataField="date90PercentCollected" caption='Date 90% collected' width={100}  dataType='date' allowEditing={false}></Column>
        <Column dataField="finalPayoutAmount" caption='Final Payout Amount' width={100} dataType='number' format={'$#,##0.00'} allowEditing={false}></Column>
        <Column dataField="isChangeOrder" caption='C/O?' dataType='boolean' width={80} allowEditing={false}></Column>
        <Column dataField="projectStatusId" caption='Project Status'  width={110} allowEditing={false}>
          <Lookup
            dataSource={projectStatuses}
            displayExpr="projectStatusName"
            valueExpr="projectStatusId"
          />
          <RequiredRule />
        </Column>
        <Column dataField="commissionPayoutStatusName" caption='Payout Status'  width={110} allowEditing={false}></Column>
        <Column dataField="actualCollectedAmount" caption='Collected Amount'  width={120} dataType='number' format={'$#,##0.00'} ></Column>
        <Column dataField="notes" caption='Notes'  width={200} allowEditing={isSalespersonLoggedIn} fixedPosition='right' fixed={isSalespersonLoggedIn}></Column>
        {/* <Column dataField="dateCollected" caption='Date of Collection' width={110} fixedPosition='right' fixed={!isSalespersonLoggedIn}  allowEditing={true}  dataType='date'></Column>         */}
                
        <Column dataField="createdOn" caption='Created On' width={150} dataType='datetime' visible={false}></Column>
        <Column dataField="createdBy" caption='Created By' width={120} visible={false}></Column>
        <Column dataField="updatedOn" caption='Updated On' width={150}  dataType='datetime'  visible={false}></Column>
        <Column dataField="updatedBy" caption='Updated By' width={120}  visible={false}></Column>
        
        <ColumnFixing enabled={true} />
        <Summary>
          <TotalItem column="jobNumber" displayFormat="Totals:" />
          <TotalItem column="sellPrice" summaryType="sum" valueFormat="$#,##0.00" displayFormat="{0}" />
          <TotalItem column="costAmount" summaryType="sum" valueFormat="$#,##0.00" displayFormat="{0}" />
          <TotalItem column="bookedGrossMargin" summaryType="sum" valueFormat="$#,##0.00" displayFormat="{0}" />
          <TotalItem column="payGrossMargin" summaryType="sum" valueFormat="$#,##0.00" displayFormat="{0}" />
          <TotalItem column="grossMarginPercentage" summaryType="avg" valueFormat="#0.00%" displayFormat="{0}" />
          <TotalItem column="totalCommision" summaryType="sum" valueFormat="$#,##0.00" displayFormat="{0}" />
          <TotalItem column="firstPayoutAmount" summaryType="sum" valueFormat="$#,##0.00" displayFormat="{0}" />
          <TotalItem column="remainingPayoutAmount" summaryType="sum" valueFormat="$#,##0.00" displayFormat="{0}" />
          <TotalItem column="finalPayoutAmount" summaryType="sum" valueFormat="$#,##0.00" displayFormat="{0}" />
        </Summary>
        
        <ColumnChooser
          enabled={true}
          mode={'select'}
        >
          <Position
            my="right top"
            at="right bottom"
            of=".dx-datagrid-column-chooser-button"
          />

          <ColumnChooserSearch
            enabled={true}
          />

          <ColumnChooserSelection
            allowSelectAll={true}
            selectByClick={true}
            recursive={true} />
        </ColumnChooser>
        <Export enabled={true} allowExportSelectedData={true} />
        {/* 
        <Toolbar>
          <Item location="after">
            <Button
              onClick={addChangeOrder}
              icon="copy"
              text="Add Change Order For Selected Proejct" />
          </Item>
        </Toolbar>
         */}

      </DataGrid>

    </div>
  );



});


export default CtrGridProjectsChangeOrders;
